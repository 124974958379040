.carousel-div{
    width: 80%;
    height: 80%;
    display: block;
    margin: auto;
}

.inner-carousel{
    border-radius: 50%;
    border-radius: 30%;
}