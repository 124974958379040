.card {
    padding: 10px;
    margin: 1px;
    overflow-x: auto;
    min-height: 420px;
  }
  
  .card-link{
    text-decoration:none;
    color: rgb(0, 0, 0);
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
  }
  
  .card-title{
    font-size: 1.2rem;
  }
  
  .card-description{
    font-size: 1rem;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  
  
  
  
  /* square buttons */
  .rec .rec-arrow {
    border-radius: 0;
    background-color: rgb(175, 190, 190);
  }
  /* round buttons on hover */
  .rec.rec-arrow:hover {
    border-radius: 25%;
    
  }
  
  .rec.rec-arrow-left:hover:enabled,
  .rec.rec-arrow-right:hover:enabled{
      background-color:rgb(79, 110, 97);
      
  }
  .rec.rec-arrow-left{
    color:black;
  }
  /* hide disabled buttons */
  .rec.rec-arrow:disabled {
    visibility: hidden;
  }
  /* disable default outline on focused items */
  /* add custom outline on focused items */
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;  
  }
  
  button.rec-dot{
    box-shadow: 0 0 1px 3px rgba(79, 80, 79, 0.5);
  }
  
  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
    box-shadow: 0 0 1px 3px rgba(8, 223, 19, 0.5);
  }
  