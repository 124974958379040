.nav-color{
    background: rgb(134,218,79);
    background: linear-gradient(90deg, 
    rgba(186, 219, 164, 0.594) 3%, 
    rgba(237, 247, 234, 0.594) 74%);
    font-size: x-large;
    font-style: italic;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}


.navLink{
    margin-right: 15%;
}

.DropDown-text{
    font-size: large;
}