
.welcome{
   color: rgb(28, 72, 83);
   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   text-shadow: 1px 1px rgb(121, 168, 139);
}


.caption {
   position: absolute;
   top: 30%;
   left: 50%;
   color: rgb(73, 65, 65);
   transform: translate(-50%, -50%);
   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   text-shadow: 1px 1px rgb(121, 168, 139),
                2px 2px rgb(255, 255, 255);
   
 }

 .img-text{
    font-size: 2rem;
 }

 .hardiness{
  background-color: rgba(12, 129, 35, 0.112);
 }