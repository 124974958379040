.popup {
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 5px;
    right: 30px;
    font-size: 60px;
    color: #121111;
    cursor: pointer;
    z-index: 9999;
  }
  
  .map-thumbnail{
    cursor: pointer;
    
  }